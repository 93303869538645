<template>
  <div>
    <v-card :loading="isLoading">
      <v-card-title>
        Diğer Gelir

        <v-col class="text-end">
          <v-btn
            class="me-2"
            color="pink"
            outlined
            @click="handleDeleteClick"
            v-if="
              id &&
              can('edit-other-income') &&
              originalData.expense_type_id === 10001
            "
            small
          >
            Sakin-BB Eşleştir
          </v-btn>

          <rs-confirm
            ref="confirmDelete"
            :loading="isLoading"
            @confirmed="handleDeleteConfirmed"
            @cancelled="$refs.confirmDelete.hide()"
          />

          <v-btn
            class="me-2"
            color="pink"
            outlined
            @click="showActionLogs"
            v-if="id && can('edit-other-income')"
            icon
            small
          >
            İ
          </v-btn>
        </v-col>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
      </v-card-title>
      <v-card-text>
        <p v-if="bankActivity">
          <em>{{ bankActivity.description }}</em> açıklamalı banka hareketi için
          diğer gelir oluşturuyorsunuz.
        </p>
        <v-form ref="form" @submit.prevent="handleFormSubmit" v-if="clusterId">
          <v-row dense cols="12">
            <v-col>
              <v-row dense cols="12">
                <v-col sm="6">
                  <rs-select
                    label="Diğer Gelir Türü"
                    v-model="formData.expense_type_id"
                    :rules="[rules.required]"
                    :items="customExpenseTypeList"
                    :filled="!editable || disabled"
                    :readonly="!editable || disabled"
                  >
                    <template
                      slot="append-outer"
                      v-if="!formData.expense_type_id || !disabled"
                    >
                      <v-btn
                        small
                        outlined
                        :to="{ name: 'other-definitions.other-incomes.create' }"
                        color="pink"
                        title="Yeni diğer gelir türü eklemek için tıklayın"
                      >
                        Ekle
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </rs-select>
                </v-col>
                <v-col sm="6">
                  <rs-select-provider
                    v-model="formData.provider_id"
                    :rules="[rules.required]"
                    :filled="!editable || disabled"
                    :readonly="!editable || disabled"
                    v-if="originalData.expense_type_id !== 10001"
                  >
                    <template
                      slot="append-outer"
                      v-if="!formData.provider_id || !disabled"
                    >
                      <v-btn
                        small
                        outlined
                        :to="{ name: 'definitions.cluster-providers.create' }"
                        color="pink"
                        title="Yeni tedarikçi eklemek için"
                      >
                        Ekle
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </rs-select-provider>
                </v-col>
                <v-col sm="4">
                  <rs-money-field
                    v-model="formData.amount"
                    :rules="
                      !editable || disabled
                        ? []
                        : [
                            rules.required,
                            rules.min(formData.amount, 0.01),
                            rules.max(formData.amount, 999999.99),
                          ]
                    "
                    :disabled="!editable || disabled || bankActivity"
                  />
                </v-col>
                <v-col sm="4">
                  <rs-datepicker
                    label="Tarih"
                    v-model="formData.issues_on"
                    :rules="!editable || id || disabled ? [] : [rules.required]"
                    :disabled="!editable || id || disabled || bankActivity"
                  />
                </v-col>
                <v-col sm="4">
                  <rs-text-field
                    v-model="formData.document_no"
                    :rules="[rules.maxLength(formData.document_no, 30)]"
                    label="Belge No"
                    :filled="!editable || disabled"
                    :readonly="!editable || disabled"
                  />
                </v-col>
                <v-col sm="4">
                  <rs-select-project
                    v-model="formData.project_id"
                    :filled="!editable || disabled"
                    :readonly="!editable || disabled"
                  />
                </v-col>
                <v-col sm="4">
                  <rs-select-resident
                    v-model="formData.resident_id"
                    :cluster-id="clusterId"
                    :filled="!editable || disabled"
                    :readonly="!editable || disabled"
                  />
                </v-col>
                <v-col sm="4" v-if="id">
                  <rs-money-field
                    label="Kalan Tutar"
                    :value="remainingAmount"
                    disabled
                  />
                </v-col>
                <v-col>
                  <rs-textarea
                    label="Açıklama"
                    v-model="formData.description"
                    rows="5"
                    :filled="!editable || disabled"
                    :readonly="!editable || disabled"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <rs-form-buttons
            :is-loading="isLoading"
            @cancel="handleCancelClick"
            @submit="handleFormSubmit"
            v-if="!disabled"
          />
        </v-form>
        <p v-else>Lütfen üst menüden bir toplu yaşam alanı seçin.</p>
        <ActionLogList ref="actionLogList" />
      </v-card-text>
    </v-card>

    <v-card v-if="originalData.id" class="mt-3">
      <v-card-title>Ödemeler</v-card-title>
      <v-card-text>
        <OtherIncomePaymentList
          ref="paymentList"
          :other-income-id="originalData.id"
          :other-income="originalData"
          :totals.sync="totals"
          :cantCrud="disabled || originalData.expense_type_id === 10001"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ActionLogList from "@/view/content/action-logs/ActionLogList";
import { hasForm, hasPermissions, isPage } from "@/view/mixins";
import OtherIncomePaymentList from "./OtherIncomePaymentList";

export default {
  name: "OtherIncomeForm",
  mixins: [hasForm, hasPermissions, isPage],
  components: {
    ActionLogList,
    OtherIncomePaymentList,
  },
  computed: {
    ...mapGetters(["clusterId", "positiveExpenseTypeList"]),
    bankActivityId() {
      return this.$route.query.bank_activity_id;
    },
    customExpenseTypeList() {
      if (!this.positiveExpenseTypeList) {
        return [];
      }

      return this.positiveExpenseTypeList;

      // if (!this.editable) {
      //   return this.positiveExpenseTypeList;
      // }

      // return this.positiveExpenseTypeList.filter((item) => {
      //   return !!item.cluster_id;
      // });
    },
    editable() {
      if (!this.id) {
        return true;
      }

      if (this.originalData.collected_on) {
        return false;
      }

      return true;
    },
    remainingAmount() {
      if (this.originalData.amount === null) {
        return null;
      }

      if (this.totals.amount > -1) {
        return (
          (this.originalData.amount * 100 - this.totals.amount * 100) / 100
        );
      }

      return null;
    },
  },
  watch: {
    id: {
      handler(newId) {
        this.formData = {
          expense_type_id: null,
          provider_id: null,
          amount: null,
          issues_on: new Date().toJSON().split("T")[0],
          project_id: null,
          resident_id: null,
          description: null,
          document_no: null,
        };

        this.documentCount = null;
        this.noteCount = null;
        this.isCollected = false;

        if (newId) {
          this.load();
        }
      },
      immediate: true,
    },
    isCollected(newValue) {
      if (newValue) {
        this.formData.collected_on = new Date().toJSON().split("T")[0];
      }
    },
    bankActivityId: {
      immediate: true,
      handler(newValue) {
        if (!this.id) {
          if (newValue) {
            this.loadBankActivity();
          } else {
            this.bankActivity = null;
            delete this.formData.bank_activity_id;
          }
        }
      },
    },
  },
  data() {
    return {
      pageMeta: {
        title: "Diğer Gelir İşlemi Ekle",
      },
      isCollected: false,
      totals: {},
      bankActivity: null,
      expenseType: null,
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);
      formData.items = [].concat(this.items);

      if (!this.id) {
        formData.cluster_id = this.clusterId;
      }

      if (this.id) {
        this.$api
          .put(`income/other-incomes/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved", this.formData);
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post("income/other-incomes", formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$nextTick(() => this.$emit("saved", this.formData));
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "incomes.other.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    load() {
      if (!this.id) {
        return;
      }

      this.isLoading = true;

      return this.$api
        .query(`income/other-incomes/${this.id}`)
        .then((response) => {
          this.loadData(response);

          if (this.originalData.collected_on) {
            this.isCollected = true;
          }

          if (!this.disabled) {
            document.getElementsByClassName("formatted-text")[0].innerHTML =
              this.numberToLocaleFormat(this.formData.amount);
          }

          this.pageMeta.title = "Diğer Gelir İşlemi";
          // this.updateClusterId(this.originalData.cluster_id);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadBankActivity() {
      if (this.bankActivityId) {
        this.isLoading = true;

        this.$api
          .get("income/bank-activities", this.bankActivityId)
          .then((response) => {
            this.bankActivity = response.data.data;

            this.formData.amount =
              (this.bankActivity.amount * 100 -
                this.bankActivity.transaction_amount * 100) /
              100;
            this.formData.description = this.bankActivity.description;
            this.formData.bank_activity_id = this.bankActivity.id;
            const issuesOn = new Date(this.bankActivity.activity_on);
            this.formData.issues_on = issuesOn.toJSON().split("T")[0];

            return this.$api.get(
              "bank-accounts",
              this.bankActivity.bank_account_id
            );
          })
          .then((response) => {
            this.updateClusterId(response.data.data.cluster_id);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    showActionLogs() {
      this.$refs.actionLogList.show(
        "App\\Models\\Income\\OtherIncome",
        this.id
      );
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        "Diğer geliri silmek istediğinizden emin misiniz?"
      );
    },
    handleDeleteConfirmed() {
      if (
        this.isLoading ||
        !this.$refs.paymentList.list[0].bank_activity_transaction
          .bank_activity_id
      ) {
        return false;
      }

      this.$api
        .delete(`income/other-incomes/${this.id}`)
        .then(() => {
          this.$toast.success("Diğer gelir silindi");
          this.$router.push({
            name: "incomes.bank.open.list",
            query: {
              id: this.$refs.paymentList.list[0].bank_activity_transaction
                .bank_activity_id,
              action: "collecting",
            },
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
