<template>
  <v-card>
    <v-card-text>
      <v-data-table
        :fixed-header="false"
        :height="undefined"
        v-bind="dataTableAttrs"
        :headers="headers"
        :items="list"
        :loading="isLoading"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
        disable-pagination
        disable-filtering
        disable-sort
      >
        <template v-slot:top>
          <rs-table-title-bar
            title="Diğer Gelir Ödemeleri"
            icon="mdi-rotate-right-variant"
            @reload="loadList"
            :hide-edit="!can('edit-other-income')"
            :edit-enabled="selectedItems.length === 1 && !cantCrud"
            @click:edit="handleEditClick"
            :show-delete="can('delete-other-income')"
            :delete-enabled="selectedItems.length > 0 && !cantCrud"
            @click:delete="handleDeleteClick"
            :add-route="remainingAmount !== 0 && !cantCrud ? '#' : null"
            @click:add="handleAddClick"
            hide-import
            hide-export
            hide-filter-clear
            hide-search
          >
            <template v-slot:buttons:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="float-end"
                    color="pink"
                    text
                    icon
                    small
                    @click.prevent="$refs.receiptForm.show(selectedItems[0].id)"
                    :disabled="selectedItems.length !== 1"
                    v-on="on"
                    v-bind="attrs"
                    v-if="can('print')"
                  >
                    <v-icon>mdi-receipt-text</v-icon>
                  </v-btn>
                </template>

                Makbuz Yazdır
              </v-tooltip>
            </template>
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }" v-if="!cantCrud">
          <a
            class="btn btn-icon btn-sm btn-clean"
            href="#"
            @click.prevent="handleEditClick(item)"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </a>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.bank_safe="{ item }">
          <router-link
            v-if="item.bank_account"
            :to="{
              name: 'definitions.bank-accounts.edit',
              hash: '#transactions',
              params: { id: item.bank_account_id },
            }"
          >
            {{ item.bank_account.name }}
          </router-link>
          <router-link
            v-else-if="item.safe"
            :to="{
              name: 'definitions.safes.edit',
              hash: '#transactions',
              params: { id: item.safe_id },
            }"
          >
            {{ item.safe.name }}
          </router-link>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.description="{ value }">
          <rs-table-cell-string :limit="20" :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.collected_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>
      </v-data-table>

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteConfirmed"
        @cancelled="$refs.confirmDelete.hide()"
      />

      <OtherIncomePaymentForm ref="paymentForm" @saved="loadList" />
      <OtherIncomePaymentReceiptForm ref="receiptForm" />
    </v-card-text>
  </v-card>
</template>

<script>
import { hasDataTable, hasPermissions } from "@/view/mixins";
import {
  OtherIncomePaymentForm,
  OtherIncomePaymentReceiptForm,
} from "@/view/pages/income/forms";

export default {
  name: "OtherIncomePaymentList",
  mixins: [hasDataTable, hasPermissions],
  components: {
    OtherIncomePaymentForm,
    OtherIncomePaymentReceiptForm,
  },
  props: {
    otherIncomeId: {
      type: Number,
      required: true,
      default: null,
    },
    otherIncome: {
      type: Object,
      required: false,
      default: () => {},
    },
    cantCrud: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    remainingAmount() {
      if (this.otherIncome.amount === null) {
        return null;
      }

      if (this.footTotals.amount > -1) {
        return (
          (this.otherIncome.amount * 100 - this.footTotals.amount * 100) / 100
        );
      }

      return null;
    },
  },
  watch: {
    otherIncomeId() {
      this.loadList();
    },
  },
  mounted() {
    if (this.otherIncomeId) {
      this.loadList();
    }
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Tarih",
          value: "collected_on",
          searchable: false,
          sortable: false,
        },
        {
          text: "Tutar",
          value: "amount",
          searchable: false,
          sortable: false,
          align: "end",
        },
        {
          text: "Banka / Kasa",
          value: "bank_safe",
          searchable: false,
          sortable: false,
        },
        {
          text: "Belge No",
          value: "document_no",
          searchable: false,
          sortable: false,
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: false,
          sortable: false,
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.otherIncomeId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];
      this.selectedItems = [];

      // const params = Object.assign(
      //   this.buildSearchParams(this.search, this.options)
      // );

      this.$api
        .query(`income/other-incomes/${this.otherIncomeId}/payments`)
        .then((response) => {
          if (response.data && response.data.data) {
            const list = [];
            for (const index in response.data.data) {
              const item = response.data.data[index];
              if (item.collected_on) {
                item.collected_on = new Date(item.collected_on);
              }
              list.push(item);
            }
            this.list = list;

            if (this.list.length) {
              let total = 0;

              for (const index in this.list) {
                total += parseInt(this.list[index].amount * 100);
              }

              this.footTotals = {
                amount: total / 100,
              };
            }

            this.$emit("update:totals", this.footTotals);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleAddClick() {
      this.$refs.paymentForm.show(null, this.otherIncomeId, null, {
        amount: this.remainingAmount,
      });
    },
    handleEditClick(item) {
      this.$refs.paymentForm.show(
        item.id || this.selectedItems[0].id,
        null,
        true
      );
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems.length} tane diğer geliri silmek istediğinizden emin misiniz?`
      );
    },
    handleDeleteConfirmed() {
      if (!this.selectedItems.length || this.isLoading) {
        return false;
      }

      const selectedItemIds = this.selectedItems.map((item) => item.id);
      const promises = [];

      this.isLoading = true;

      for (const index in selectedItemIds) {
        const promise = this.delete(selectedItemIds[index]);
        promises.push(promise);
      }

      Promise.all(promises).finally(() => {
        this.isLoading = false;
        this.$refs.confirmDelete.hide();
        this.loadList();
      });
    },
    delete(id) {
      return this.$api
        .delete(`income/payments/${id}`)
        .then(() => {
          this.$toast.success("Diğer gelir silindi");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
